import AnalyticsUsersListItem from "components/analytics/AnalyticsUsersListItem";
import Fade from "components/animations/Fade";
import Switch from "components/animations/Switch";
import EmptyContent from "components/base/EmptyContent";
import { useBackground } from "components/base/layout/Background";
import Heading1 from "components/base/text/Heading1";
import Select from "components/form/Select";
import { useNavigateWithFade } from "components/PageRouter/PageRouter";
import useAnalyticsGroup from "hooks/analytics/useAnalyticsGroup";
import useAnalyticsGroupUsers from "hooks/analytics/useAnalyticsGroupUsers";
import useGetCoachGroups, { CoachGroups } from "hooks/useGetCoachGroups";
import { ChangeEvent, useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

export default function NewAnalyticsGroup() {
  useBackground("rgb(102,77,243)", true);
  const { groupid: groupId } = useParams<{ groupid: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const navigateWithFade = useNavigateWithFade();

  const promptSetId = searchParams.get("promptsetid") || undefined;

  const { coachGroups, isCoachGroupsLoading } = useGetCoachGroups();
  const { analyticsGroup } = useAnalyticsGroup(groupId, promptSetId);
  const { analyticsGroupUsers } = useAnalyticsGroupUsers({ groupId, promptSetId });

  useEffect(() => {
    if (!groupId && coachGroups?.length) {
      navigate(`/analytics/group/${coachGroups[0].id}`, { replace: true });
    }
  }, [coachGroups, groupId, navigate]);

  if (isCoachGroupsLoading) return null;

  if (!isCoachGroupsLoading && !coachGroups?.length) return <EmptyContent />;

  const { group } = analyticsGroup || {};

  const handleGroupSelectorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    navigate(`/analytics/group/${e.target.value}`);
    e.stopPropagation();
  };

  return (
    <Fade delay={200}>
      <GroupsSelectorContainer>
        <Select label="Groups" onChange={handleGroupSelectorChange} value={groupId}>
          {coachGroups &&
            flattenCoachGroups(coachGroups).map?.(groupItem => {
              return (
                <option key={groupItem.id} id={groupItem.id} value={groupItem.id}>
                  {groupItem.name}
                </option>
              );
            })}
        </Select>
      </GroupsSelectorContainer>
      <Switch renderKey={groupId + "-"}>
        {group && (
          <Page>
            <Header>
              {group?.name}
              <Heading1>Group Analytics</Heading1>
            </Header>
            {analyticsGroupUsers?.length && (
              <>
                <Limiter>
                  <UserListContainer>
                    {analyticsGroupUsers?.map(item => (
                      <div
                        onClick={() =>
                          navigateWithFade(location.pathname + "/user/" + item.user.id)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <AnalyticsUsersListItem cycle={item} />
                      </div>
                    ))}
                  </UserListContainer>
                </Limiter>
              </>
            )}
          </Page>
        )}
      </Switch>
    </Fade>
  );
}

function flattenCoachGroups(
  groups: CoachGroups,
  level: number = 0,
): { id: string; name: string }[] {
  let result: { id: string; name: string }[] = [];

  groups.forEach(group => {
    // Add current group with indentation
    result.push({
      id: group.id,
      name: `${"\u00A0".repeat(level * 4)}${group.name}`,
    });

    // Recursively process children
    if (group.children && group.children.length > 0) {
      result = result.concat(flattenCoachGroups(group.children, level + 1));
    }
  });

  return result;
}

const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem 0;
`;

const Header = styled.div`
  color: white;
  text-align: center;
`;

const UserListContainer = styled.div`
  display: grid;
  flex: 1;
  flex-wrap: wrap;
  gap: 1rem;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;
  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 960px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const GroupsSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
  padding: 1.25rem 1rem;
  width: calc(100vw - 5rem);
  @media (min-width: 640px) {
    flex-direction: row;
    & > div {
      width: 100%;
    }
  }
`;

const Limiter = styled.div`
  margin: 0 auto;
  max-width: 100%;
  padding: 0 1rem;
  width: 1120px;
`;
