import { ArrowDown } from "components/base/Arrow";
import { useFormikContext } from "formik";
import { ComponentProps, useEffect, useState } from "react";
import styled from "styled-components";

export interface SelectProps<T> extends ComponentProps<typeof InvisibleSelect> {
  name?: keyof T;
  error?: string;
  focused?: boolean;
  loading?: boolean;
}

export default function Select<T>({ name, value, loading, ...props }: SelectProps<T>) {
  const { errors, touched, setFieldValue, values } = useFormikContext<T>() || {};
  const [isFocused, setIsFocused] = useState(false);
  const error = props.error || (name && touched?.[name] && errors[name]);

  useEffect(() => {
    if (name && ["true", "false"].includes(values[name] as string)) {
      setFieldValue(name as string, values[name] === "true");
    }
  }, [values, name, setFieldValue]);

  const parseValue = (value: string | boolean) =>
    value === "true" ? true : value === "false" ? false : value;

  const option = props.children?.find(
    (child: any) => parseValue(child.props.value) === value,
  );

  return (
    <Wrapper {...props}>
      <StyledSelect
        error={!!error}
        focused={isFocused || props.focused}
        placeholder={option?.props.disabled}
      >
        {option?.props.children}
      </StyledSelect>
      <InvisibleSelect
        {...props}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        value={parseValue(value)}
      />
      <Arrow />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const Arrow = styled(ArrowDown)`
  pointer-events: none;
  position: absolute;
  right: 0.5rem;
  top: 1.3rem;
  transform: translateY(-50%);
`;

const StyledSelect = styled.div<{
  error?: boolean;
  focused?: boolean;
  placeholder?: boolean;
}>`
  background: #f9f7f4;
  border: none;
  border-radius: 99px;
  box-sizing: content-box;
  color: ${({ placeholder }) => (placeholder ? "#a8a8a8" : "#241f18")};
  height: 1.25rem;
  line-height: 1.25rem;
  outline: 2px solid ${({ error }) => (error ? "#f44336" : "transparent")};
  overflow: hidden;
  padding: 0.75rem 1rem;
  padding-right: 3rem;
  text-overflow: ellipsis;
  transition: outline-color 0.2s ease-in-out;
  white-space: nowrap;
  width: fill-available;
  &:disabled {
    opacity: 0.5;
  }
  &:hover ~ ${Arrow} {
    transform: translateY(-50%) scale(1.04);
  }
  &:focus {
    outline-color: ${({ error }) => (error ? "#f44336" : "#664df3")};
  }
  ${({ focused, error }) => {
    if (focused) {
      return `
        outline-color: ${error ? "#f44336" : "#664df3"};
        & ~ ${Arrow} {
          transform: translateY(-50%) scale(1.04);
        }
      `;
    }
  }}
`;

const InvisibleSelect = styled.select`
  -webkit-appearance: menulist-button;
  bottom: 0;
  cursor: pointer;
  left: 0;
  line-height: 1.25rem;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
`;
