import { AnalyticsProcessingStatus } from "@dailyhuman/web-core/dist/api/game";
import { IListGameChatHistoryRes } from "@dailyhuman/web-core/dist/api/game/res";
import { LaunchGameType } from "@dailyhuman/web-core/dist/api/onboarding/types";
import Fade from "components/animations/Fade";
import EmptyContent from "components/base/EmptyContent";
import { useBackground } from "components/base/layout/Background";
import Media from "components/base/Media";
import Epoch from "components/base/text/Epoch";
import Heading1 from "components/base/text/Heading1";
import { Message } from "components/lab/Message";
import MessagingInput from "components/lab/MessagingInput";
import { useGetGameMessages } from "hooks/useGetGameMessages";
import { Buckets, useGetUserGames } from "hooks/useGetUserGames";
import { RefObject, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as NoInternetIcon } from "../../assets/img/NoInternet.svg";
import { ReactComponent as ToTheBottom } from "../../assets/img/ToTheBottomButton.svg";

const ANSWER_TIMEOUT = 10000;

export const Labs = () => {
  const { games, isGamesLoading } = useGetUserGames();
  const navigate = useNavigate();

  const { messages, isMessagesLoading, isMessagingListError } = useGetGameMessages({
    gameCycleId: games?.data[0]?.cycleId,
    isEnabled: !!games?.data[0],
  });

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messages?.meta?.lab?.analyticsStatus === AnalyticsProcessingStatus.COMPLETED) {
      let timeout = setTimeout(() => navigate("/"), 1500);
      return () => clearTimeout(timeout);
    }
  }, [messages?.meta?.lab?.analyticsStatus, navigate]);

  useBackground("#f9f7f4");
  if (isGamesLoading || !games) return null;
  if (
    !games?.data ||
    games?.data.length === 0 ||
    messages?.data.length === 0 ||
    !messages
  )
    return (
      <PageContainer>
        <EmptyContent
          title="You're all caught up :)"
          subtitle="Stay tuned for your next prompt"
        />
      </PageContainer>
    );

  const firstGameCycle = games.data[0];
  const partnerName = firstGameCycle.partner.name;

  return (
    <PageContainer className="PageContainer">
      <Helmet>
        <meta name="theme-color" content="#eee7df" />
      </Helmet>
      <ChatContainer ref={ref}>
        <ChatWidthLimiter>
          <Heading1>{games?.data[0].prompt.name}</Heading1>
          <Messages
            messages={messages}
            partnerName={partnerName}
            isMessageSending={true}
            isMessagesLoading={isMessagesLoading}
            parentComponentRef={ref}
            game={games?.data[0]}
          />
        </ChatWidthLimiter>
      </ChatContainer>
      <InputContainerWrapper>
        <InputContainer>
          {(!navigator.onLine || isMessagingListError) && (
            <Fade delay={1}>
              <ConnectionProblemsContainer>
                <ConnectionProblems>
                  <span>Check your connection</span>
                  <NoInternetIcon />
                </ConnectionProblems>
              </ConnectionProblemsContainer>
            </Fade>
          )}
          <Fade>
            <MessagingInput game={games?.data[0]} messages={messages} />
          </Fade>
        </InputContainer>
      </InputContainerWrapper>
    </PageContainer>
  );
};

const Messages = ({
  messages,
  partnerName,
  isMessageSending,
  isMessagesLoading,
  parentComponentRef,
  game,
}: {
  messages?: IListGameChatHistoryRes;
  isMessagesLoading: boolean;
  partnerName: string;
  isMessageSending: boolean;
  parentComponentRef: RefObject<HTMLDivElement>;
  game: Buckets;
}) => {
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const [timeoutReached, setTimeoutReached] = useState(false);
  const [isOnBottom, setOnBottom] = useState(false);

  const isLastMessageFromUser =
    messages?.data[messages.data.length - 1]?.direction === "IN";

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (parentComponentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = parentComponentRef.current;

        if (scrollTop + clientHeight >= scrollHeight - 100) {
          setOnBottom(true);
        } else {
          setOnBottom(false);
        }
      }
    };

    const scrollableElement = parentComponentRef.current;
    scrollableElement?.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      scrollableElement?.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (messages && messages?.data.length > 1) setTimeout(scrollToBottom, 500);
  }, [messages]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isLastMessageFromUser) {
      timer = setTimeout(() => {
        setTimeoutReached(true);
      }, ANSWER_TIMEOUT);
    } else {
      setTimeoutReached(false);
    }

    return () => clearTimeout(timer);
  }, [isLastMessageFromUser]);

  if (!messages?.data && isMessagesLoading) return null;
  if (!messages?.pagination.total)
    return <p>Hang tight! Here you will see your messages.</p>;

  return (
    <>
      <div>
        {game.prompt.greeting && (
          <ServerMessageWrapper>{game.prompt.greeting}</ServerMessageWrapper>
        )}
        {game.prompt.media && (
          <MediaContainer>
            <Media uri={game.prompt.media} />
          </MediaContainer>
        )}
      </div>
      {messages.data.map((message, index) => {
        return (
          <Fade delay={1} key={message.id}>
            <MessageWrapper isFirst={index === 0}>
              <Message
                key={message.id}
                isUserMessage={message?.direction === "IN"}
                message={message.message}
              />
            </MessageWrapper>
            {index === 0 && (
              <div>
                <ServerMessageWrapper>
                  {game.game.type !== LaunchGameType.SALES_TEST_GAME_ONLY && (
                    <b>
                      Respond by <Epoch date={game.forceCompleteAt} />
                    </b>
                  )}
                  {game.game.type === LaunchGameType.COMMUNITY_GAME && (
                    <>
                      <br />
                      {`Your reflection will be shared with ${partnerName}.`}
                    </>
                  )}
                  <br />
                  <br />
                </ServerMessageWrapper>
              </div>
            )}
          </Fade>
        );
      })}
      {isLastMessageFromUser && !isMessageSending && !timeoutReached && (
        <Fade delay={0}>
          <DailyHumanTyping>Typing ...</DailyHumanTyping>
        </Fade>
      )}
      {timeoutReached && !isMessageSending && (
        <Fade delay={0}>
          <DailyHumanTyping>This is taking longer than expected ...</DailyHumanTyping>
        </Fade>
      )}
      {!isMessageSending &&
        !(isLastMessageFromUser && !isMessageSending && !timeoutReached) && (
          <ToTheBottomButtonContainer isVisible={!isOnBottom} onClick={scrollToBottom}>
            <ToTheBottom />
          </ToTheBottomButtonContainer>
        )}
      <div ref={messagesEndRef}></div>
    </>
  );
};

const ToTheBottomButtonContainer = styled.div<{ isVisible: boolean }>`
  align-content: center;
  align-items: center;
  bottom: 0;
  cursor: pointer;
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  left: 0;
  margin-top: -1rem;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  position: sticky;
  transition: opacity 0.3s ease-in-out;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  width: 100%;
  z-index: 1000;
`;

const DailyHumanTyping = styled.div`
  background: transparent;
  color: #363636;
  font-style: italic;
  font-weight: 300;
  line-height: 33px;
  margin: 5px;
  text-align: left;
  z-index: 10;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100dvh;
  overflow: hidden;
  > p {
    color: #363636;
    font-style: normal;
    font-weight: 600;
    margin: 97px auto;
    max-width: 413px;
    text-align: center;
  }
`;

const ChatContainer = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  h1 {
    padding: 1rem 1rem 0 1rem;
  }
  &:after {
    background: linear-gradient(180deg, #f9f7f4 1%, rgba(249, 247, 244, 0) 80%);
    content: "";
    height: 3rem;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
`;

const MediaContainer = styled.div`
  max-width: 480px;
  padding: 1rem;
  width: 92%;
`;

const ConnectionProblemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
`;

const ConnectionProblems = styled.div`
  background-color: #f65150;
  border-radius: 36px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  width: 230px;
  > span {
    color: #fae6e2;
  }
  > svg {
    background-color: #f9f7f4;
    border-radius: 50%;
    padding: 2px;
  }
`;

const ChatWidthLimiter = styled.div`
  margin: 0 auto;
  max-width: 768px;
`;

const InputContainerWrapper = styled.div`
  background: #eee7df;
  height: auto;
  width: 100%;
`;

const InputContainer = styled.div`
  margin: 0 auto;
  max-width: 768px;
  padding: 0 0.5rem;
  width: 100%;
`;

const MessageWrapper = styled.div<{ isFirst: boolean }>`
  font-weight: ${({ isFirst }) => (isFirst ? "600" : "normal")};
  > div {
    margin-bottom: ${({ isFirst }) => (isFirst ? "0" : "2rem")};
    padding: 1rem 1rem 0 1rem;
  }
`;
export const ServerMessageWrapper = styled.div`
  color: #676464;
  max-width: 800px;
  padding: 1rem 1rem 0 1rem;
  white-space: pre-line;
  & > div {
    margin: 1rem 0 -2rem;
    position: relative;
  }
  b {
    font-weight: 600;
  }
  & > div > svg {
    left: -10px;
    position: absolute;
    top: -10px;
  }
`;
